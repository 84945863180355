<template>
  <v-card :loading="loading">
    <v-card-title>
      {{ table.title }}
      <v-spacer />
      <span class="overline text--secondary"
        >Last Updated: <b>{{ table.lastUpdated }}</b></span
      >
      <v-btn icon x-small @click="fetchDashboard(true)">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-card-title>

    <v-simple-table :dense="true" :fixed-header="false" class="elevation-1" :height="height">
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="(colName, i) in table.header" :key="i" class="text-left">{{ colName }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, i) in table.items" :key="i">
            <td v-for="(value, i) in row" :key="i" v-html="value"></td>
          </tr>
        </tbody>
        <tfoot v-if="table.total">
          <tr>
            <th v-for="(value, i) in table.total" :key="i">{{ value }}</th>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    ccmsId: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      table: {
        items: [],
        header: [],
        total: [],
        title: "",
        lastUpdated: "-"
      },
      loading: false
    };
  },
  created() {
    this.fetchDashboard();
  },
  methods: {
    fetchDashboard(realTime) {
      this.loading = true;
      this.$axios
        .get("/dashboard/" + this.name, {
          params: { ccmsId: this.ccmsId, realTime: realTime }
        })
        .then((res) => {
          this.loading = false;
          this.table = res.data;
        });
    }
  }
};
</script>

<style scoped>
thead tr th:first-child,
tbody tr td:first-child,
tfoot tr th:first-child {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  word-break: break-all;

  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background: #fff;
  border-right: 1px solid #ccc;

  left: 0;
  z-index: 1 !important;
}

tfoot tr th {
  border-top: 1px solid #ccc;
}
</style>
