<template>
  <v-container fluid v-if="activeProject">
    <v-row>
      <v-col cols="12">
        <Dashboard name="list_usage" :ccmsId="activeProject" height="384px" />
      </v-col>
      <v-col cols="6">
        <Dashboard name="individual_stats" :ccmsId="activeProject" height="800px" />
      </v-col>
      <v-col cols="6">
        <Dashboard name="campaign_stats" :ccmsId="activeProject" />
      </v-col>
    </v-row>
  </v-container>
  <v-col v-else-if="activeProject !== null" cols="11">
    <p>Please click on <b>START WORK</b> to start.</p>
  </v-col>
</template>

<script>
import Dashboard from "@/components/core/Dashboard";

export default {
  computed: {
    activeProject() {
      return this.$store.state.ccms.activeProject;
    }
  },
  components: {
    Dashboard
  }
};
</script>
